body {
    background: white;
    font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin: 0;
}

a.ThemeLink {
    text-decoration: none;
}

a.ThemeLink:hover {
    opacity: 0.7;
}

.lead {
    font-size: 21px;
    line-height: 1.4;
}

.lead-huge {
    font-size: 30px;
}

.lead-big {
    font-size: 20px;
    margin: 4px;
    line-height: 1.4;
}

h1 {
    font-weight: 150;
    font-size: 45px;
}

h2 {
    margin: 0 0 10px 0;
    font-size: 30px;
    font-weight: 150;
}

h3 {
    font-size: 24px;
    font-weight: 150;
}

.TopBar {
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
}

.TopBar .name {
    font-weight: 400;
}

.TopBar .location {
    font-weight: 100;
    color: rgba(0,0,0,0.3);
}
.TopBar .loc-spinner {
    display: inline-block;
}

.TopBar .loc-spinner a {
    margin-left: 5px;
    color: rgba(0,0,0,0.3);
}

.TopBar .loc-spinner a:hover {
    color: black;
}

.TopBar .loc-spinner a.disabled:link, .TopBar .loc-spinner a.disabled:hover {
    color: rgba(0,0,0,0.3);
    opacity: 0.5;
    cursor: default;
}

.TopBar .bar {
    display: block;
    height: 25px;
}

.NonSplash .TopBar .top.bar {
    background: #F1F1F1;
}

.TopBar .middle.bar {
    display: block;
    position: relative;
    background: white;
    font-weight: bold;
    line-height: 30px;
    height: 30px;
    z-index: 900;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
}

.Splash .TopBar .middle.bar {
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.2);
}

.TopBar .left, .TopBar .right {
    position: absolute;
    display: inline-block;
    width: 260px;
}

.TopBar .left {
    right: 50%;
    text-align: right;
    padding-right: 40px;
}

.TopBar .right {
    left: 50%;
    text-align: left;
    padding-left: 40px;
}

.TopBar .avatar {
    position: absolute;
    top: 15px;
    left: 50%;
    width: 50px;
    margin-left: -25px;
    border: 2px solid white;
    border-radius: 27px;
    z-index: 1000;
}

.Content {
    padding-top: 150px;
}

.Latest {
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
    z-index: 1;
}

.Latest .Caption {
    position: absolute;
    right: 10px;
    bottom: 0px;
    font-size: 10px;
    background: rgba(0,0,0,0.5);
    color: white;
    font-style: italic;
    padding: 4px;
}

.Latest .Caption a {
    color: white;
}

.Latest .Blurb {
    position: absolute;
    display: block;
    background: white;
    width: 500px;
    padding: 17px;
    display: block;
    bottom: 30px;
    left: 30px;
}

.Latest .Blurb p {
    margin: 0 0 12px 0;
}

.Latest .Blurb .time-label {
    text-transform: uppercase;
    color: gray;
    font-size: 10px;
}

.NewsTimeline {
    display: block;
    position: relative;
}

.NewsTimeline .TimelineItem {
    
}

.NewsTimeline .TimelineItem.gap_very_long {
    margin-top: 100px;
}

.NewsTimeline .TimelineItem.gap_long {
    margin-top: 50px;
}

.NewsTimeline .TimelineItem.gap_long {
    margin-top: 30px;
}
.NewsTimeline .TimelineItem.gap_medium {
    margin-top: 20px;
}
.NewsTimeline .TimelineItem.gap_short {
    margin-top: 10px;
}
.NewsTimeline .TimelineItem.gap_tiny {
    margin-top: 0px;
}

.NewsTimeline .TimelineItem .Icon {
    padding: 15px;
}

.NewsTimeline .TimelineItem .Title {
    font-size: 14px;
    font-weight: normal;
}

.NewsTimeline .TimelineItem .Subtitle {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    font-weight: normal;
}

.NewsTimeline .TimelineItem .Link {
    color: #555;
    font-weight: normal;
}

.NewsTimeline .TimelineItem .Date.Recent {
    font-weight: bold;
    font-style: italic;
}

.NewsTimeline .TimelineItem.Recent {
}

.NewsTimeline .Line {
    display: block;
    position: absolute;
    width: 3px;
    height: 100%;
    left: 33px;
    top: 30px;
    background: #DCDCDC;
    background: -webkit-linear-gradient(bottom, #FFFFFF, #DCDCDC);
    background: -moz-linear-gradient(bottom, #FFFFFF, #DCDCDC);
    background: linear-gradient(to top, #FFFFFF, #DCDCDC);
}

.NavBar {
    position: absolute;
    display: block;
    top: 55px;
    right: 0px;
    z-index: 1000;
    margin: 0;
}

.NavBar a:link, .NavBar a:visited {
    display: inline-block;
    float: right;
    line-height: 50px;
    height: 50px;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    color: rgba(0,0,0,0.3);
    text-decoration: none;
    margin: 0 20px;
}

.NavBar a:hover, .NavBar a:active {
    /*background: rgba(255, 255, 255, 0.5);*/
    color: black;
}

.Splash .NavBar a:link, .Splash .NavBar a:visited {
    color: white;
    text-shadow: 0px 2px 2px rgba(0,0,0,0.5);
}

.NavBar a:link.here, .NavBar a:visited.here {
    color: black;
}

.Splash .NavBar a.here {
    display: none;
}

.Photos .Thumbs > a, .Photos .Thumbs > div.photo-placeholder {
    display: inline-block;
    margin: 0 3px 3px 0;
}

.Photos .InstaMain {
    border-radius: 3px;
}

.Photos .Date {
    color: gray;
    font-size: 14px;
}

.Photos .Location {
    color: gray;
    font-size: 14px;
    padding-left: 7px;
}

.footer {
    font-size: 9px;
    text-align: center;
    padding: 40px 0 20px 0;
}

.footer .Social {
    display: block;
    font-size: 23px;
}

.footer .Social a {
    margin-right: 10px;
}

@media (max-width: 600px) {
    .Latest .Blurb {
        width: auto;
        right: 30px;
    }

    .NavBar a:link, .NavBar a:visited {
        margin: 0 10px;
    }

    .TopBar .left, .TopBar .right {
        width: 220px;
    }

    .TopBar .loc-spinner {
        display: none;
    }

}

.photo-placeholder {
    display: inline-block;
    width: 50px;
    height: 50px;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
}

.Publications {
    display: block;
    margin-top: 25px;
}

.Publications .Pub {
    clear: both;
    margin-bottom: 25px;
}

.Publications .Pub .Title {
    font-size: 16px;
    font-weight: 400;
}

.Publications .Pub .Title.no_links, .Publications .Pub .Title.no_links:hover {
    color: black;
    text-decoration: none;
}

.Publications .Pub .Authors .Author {

}

.Publications .Pub .Authors .Author.Me {
    font-weight: bolder;
}

.Publications .Pub .Publication {
    font-style: italic;
    color: gray;
}

.Publications .Pub .Year {
    margin-left: 5px;
}

.ContentLinks {
    margin-top: 4px;
}

.ContentLinks a:link,
.ContentLinks a:visited,
.ContentLinks a:hover {
    color: black;
    text-transform: uppercase;
    background-color: #888;
    color: white;
    font-size: 10px;
    border-radius: 2px;
    margin-right: 5px;
    padding: 4px 8px;
    text-decoration: none;
}

.ContentLinks a:hover {
    box-shadow: 0px 1px rgba(0, 0, 0, 0.5);
}

.ContentLinks a.large {
    font-size: 12px;
    padding: 6px 10px;
}
